<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :enterpriseId="$route.params.enterpriseId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-enterprise-list' }">Enterprises</router-link> &gt;
                    <router-link :to="{ name: 'enterprise-dashboard', params: { enterpriseId: this.$route.params.enterpriseId } }">Enterprise Dashboard</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Realm overview</h1>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ displayName }}</h1>
                <p class="text-caption text-center">Realm overview</p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 px-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0"> -->
            <v-row justify="center" class="py-5 px-10" v-if="realm">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Identity Realm</v-app-bar-title>
                            <v-spacer></v-spacer>
                            <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item :to="{ name: 'cryptium_id-realm-settings', params: { enterpriseId: this.$route.params.enterpriseId } }">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'cog']" :color="primaryColor" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Settings</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </v-app-bar>
                        <v-card-text>

                        <!-- TODO: OPEN QUESTION: show {{ displayName }} and {{ name }} (alias) again? it's already in the realmbar at the top of the page...  -->

                        <!-- <p class="text-overline mb-0 mt-5">Apps</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'cryptium_id-realm-app-list', params: { enterpriseId: this.$route.params.enterpriseId, realmId: this.$route.params.realmId } }">Registered apps</router-link>
                        </p> -->

                        <!-- <router-link :to="{ name: 'cryptium_id-realm-user-list', params: { enterpriseId: this.$route.params.enterpriseId, realmId: this.$route.params.realmId } }">Registered users</router-link> -->
                        <!-- TODO: load the count of registered users (users who verified something with this realm) to show here; maybe show 10 most recently added users, or something else useful in a realm dashboard -->
                        <p class="text-overline mb-0">Total</p>
                        <p class="mb-0 pb-0">
                            Users: {{ realm.report.total_user_count }}
                        </p>
                        <p class="mb-0 pb-0">
                            Clients: {{ realm.report.total_client_count }}
                        </p>

                        <p class="text-overline mt-6 mb-0">Recent</p>
                        <p class="mb-0 pb-0">
                            Report period: <v-select v-model="recentDays" :items="recentDaysItems" outlined dense></v-select>
                        </p>
                        <p class="mb-0 pb-0">
                            New users: {{ realm.report.new_user_count }}
                        </p>
                        <p class="mb-0 pb-0">
                            New clients: {{ realm.report.new_client_count }}
                        </p>
                        <p class="mb-0 pb-0">
                            Profile requests:
                            <router-link :to="{ name: 'cryptium_id-realm-profile-request-list', params: { enterpriseId: this.$route.params.enterpriseId, realmId: this.$route.params.realmId }, query: { recent_days: recentDays } }">
                            {{ realm.report.profile_request_count }}
                            </router-link>
                        </p>
                        <p class="mb-0 pb-0">
                            Profile responses: {{ realm.report.profile_response_count }}
                        </p>
                        <p class="mb-0 pb-0">
                            Response rate: {{ responseRate }}
                        </p>

                        <!-- TODO: show {{ brandprofileAlias }} ?  it's already in settings; do we need that in dashbaord?  or maybe show an alert if brandprofile is NOT set up, to prompt admin to set it up -->

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/cryptium_id/RealmBar.vue';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
    },
    data: () => ({
        realm: null,
        // serviceConfig: null,
        forbiddenError: false,
        recentDays: 1, // how many days back to look for new records
        recentDaysItems: [
            { value: 1, text: '1 day' },
            { value: 7, text: '7 days' },
            { value: 14, text: '14 days' },
        ],
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryTextStyle: 'primaryTextStyle',
            primaryIconButtonStyle: 'primaryIconButtonStyle',
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        displayName() {
            return this.realm?.display_name ?? this.realm?.alias ?? 'Unknown realm';
        },
        name() {
            return this.realm?.alias ?? 'Unknown realm';
        },
        brandprofileAlias() {
            return this.realm?.info?.brandprofile_alias ?? ''; // '(Not connected)';
        },
        realmLogoImage() {
            return false;
        },
        responseRate() {
            if (Number.isInteger(this.realm?.report?.profile_request_count) && Number.isInteger(this.realm?.report?.profile_response_count) && this.realm.report.profile_request_count > 0) {
                const rate = (this.realm.report.profile_response_count * 100) / this.realm.report.profile_request_count;
                return `${rate.toFixed(2)}%`;
            }
            return 'n/a';
        },
    },
    watch: {
        recentDays() {
            this.loadRealm(); // reload the report with the new value
        },
    },
    methods: {
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.cryptiumId(this.$route.params.enterpriseId).realm.report({ id: this.$route.params.realmId, recent_days: this.recentDays });
                console.log(`realm/dashboard.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/dashboard.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        /*
        async loadServiceConfig() {
            try {
                this.$store.commit('loading', { loadServiceConfig: true });
                const result = await this.$client.main().setting.search();
                console.log(`realm/dashboard.vue loadServiceConfig result: ${JSON.stringify(result)}`);
                if (result && Array.isArray(result.list)) {
                    const configMap = {};
                    result.list.forEach((item) => { configMap[item.name] = item.content; });
                    this.serviceConfig = configMap;
                }
            } catch (err) {
                console.error('realm/dashboard.vue loadServiceConfig failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load service settings', message });
            } finally {
                this.$store.commit('loading', { loadServiceConfig: false });
            }
        },
        */
    },
    mounted() {
        this.loadRealm();
        // this.loadServiceConfig();
    },
};
</script>
